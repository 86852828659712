<template>
  <div>
    <ServerError v-if="ServerError" /> 
     <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />

    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-center> 
        <span class="itemHeading"> {{ dataType }} </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4>
      <v-flex xs12 lg10>
        <v-layout wrap justify-start>
          <v-flex xs12 sm12 text-left px-2>
            <span class="itemValue">{{ dataType }} </span>
            <v-text-field
              ref="name"
              color="#FDCE48"
              class="formClass"
              placeholder="Name"
              dense
              v-model="name"
            ></v-text-field>
          </v-flex>
           <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Background Color </span>
            <v-color-picker
              v-model="backgroundColor"
              dot-size="25"
              mode="hexa"
              swatches-max-height="100"
            ></v-color-picker>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Text Color </span>
            <v-color-picker
              v-model="textColor"
              dot-size="25"
              mode="hexa"
              swatches-max-height="100"
            ></v-color-picker>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-center>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="closeDialoge"
              class="itemValue"
            >
              Cancel
            </v-btn>
          </v-flex>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              color="#000"
              light
              :ripple="false"
              depressed
              @click="validateInput"
              class="itemValue"
            >
              <span style="color: #fff">Edit {{ dataType }}</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage", "dataType", "editUrl", "subItemId"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,

      name: null,
        backgroundColor: null,
      textColor: null,
    };
  },
  beforeMount() {
    this.name = this.storage.name;
    this.backgroundColor=this.storage.backgroundColor
    this.textColor=this.storage.textColor
  },
  methods: {
    closeDialoge() {
      this.$emit("stepper", {
        type: "Edit",
        getData: false,
        editUser: false,
      });
    },

    validateInput() {
      if (!this.name) {
        this.msg = "Please Provide " + this.dataType + " name";
        this.showSnackBar = true;
        return;
      }
       if (!this.backgroundColor) {
        this.msg = "Please Provide " + this.dataType + "background color";
        this.showSnackBar = true;
        return;
      } else {
        this.editTagData();
      }
    },
    editTagData() {
      var data = {};
      data["name"] = this.name;
       data["backgroundColor"] = this.backgroundColor.hexa;
      data["textColor"] = this.textColor.hexa;
      data["id"]=this.storage._id
      axios({
        method: "post",
        url: this.editUrl,
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
          this.name = null;
            this.backgroundColor = null;
            this.textColor = null;
            this.msg = this.dataType + " Edited";
            this.showSnackBar = true;

            this.$emit("stepper", {
              type: "Edit",
              getData: true,
              editData: false,
            });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>