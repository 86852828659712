var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('vue-element-loading',{attrs:{"active":_vm.appLoading,"spinner":"bar-fade-scale","color":"#1976d2","size":"128","is-full-screen":""}}),_c('v-snackbar',{attrs:{"color":"#000","right":"","timeout":_vm.timeout},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"align-self-center",attrs:{"text-left":""}},[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v(" "+_vm._s(_vm.msg)+" ")])]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',{staticStyle:{"color":"#fff"}},[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":_vm.$vuetify.breakpoint.name == 'xs' ||
      _vm.$vuetify.breakpoint.name == 'sm' ||
      _vm.$vuetify.breakpoint.name == 'md'
        ? '100vw'
        : _vm.$vuetify.breakpoint.name == 'lg'
        ? '50vw'
        : '50vw'},model:{value:(_vm.addDataDialog),callback:function ($$v) {_vm.addDataDialog=$$v},expression:"addDataDialog"}},[_c('v-card',[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-2":"","py-8":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('AddData',{attrs:{"dataType":'Product Tag',"addUrl":'/producttag/add'},on:{"stepper":_vm.winStepper}})],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":_vm.$vuetify.breakpoint.name == 'xs' ||
      _vm.$vuetify.breakpoint.name == 'sm' ||
      _vm.$vuetify.breakpoint.name == 'md'
        ? '100vw'
        : _vm.$vuetify.breakpoint.name == 'lg'
        ? '50vw'
        : '50vw'},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pa-2":"","py-8":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('EditData',{key:_vm.editTagData._id,attrs:{"storage":_vm.editTagData,"dataType":'Product Tags',"editUrl":'/producttag/edit'},on:{"stepper":_vm.winStepper}})],1)],1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-start":"","pt-3":"","pl-2":"","pr-5":""}},[_c('v-flex',{attrs:{"align-self-center":"","xs6":"","sm9":"","md10":"","text-left":"","pl-4":""}},[_c('span',{staticClass:"itemHeading1"},[_vm._v(" Product Tags ")])]),_c('v-flex',{attrs:{"align-self-center":"","xs6":"","sm3":"","md2":"","lg2":"","xl1":"","px-3":""}},[_c('v-btn',{staticClass:"itemValue",attrs:{"block":"","tile":"","depressed":"","outlined":"","color":"#000000","ripple":false},on:{"click":function($event){_vm.addDataDialog = true}}},[_vm._v(" Add New Data ")])],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-start":"","pa-4":""}},[_vm._l((_vm.productTags),function(item,i){return [_c('v-flex',{key:i,attrs:{"xs12":"","lg6":"","xl6":"","pa-2":""}},[_c('tagItem',{attrs:{"deleteUrl":'/producttag/remove',"storage":item,"dataType":'Product Tag'},on:{"stepper":_vm.winStepper}})],1)]}),(_vm.productTags.length < 1)?_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticClass:"itemKey"},[_vm._v(" No data available ")])]):_vm._e()],2),(_vm.pages > 1)?_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-pagination',{attrs:{"length":_vm.pages,"total-visible":7,"color":"#919191"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }